import { useState,useRef,useEffect,useCallback} from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LicenseInfo,DataGridPro } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TablePagination from '@mui/material/TablePagination';
import { Link,useNavigate } from 'react-router-dom';
import LockResetIcon from '@mui/icons-material/LockReset';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CancelIcon from '@mui/icons-material/Cancel';
import OrderSearch from '../search/OrderSearch';
import FinishedAlert from '../finishView/FinishedAlert';


const API_PATH = process.env.REACT_APP_API_PATH;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const columnVisibilityModel = {
  id:true
}

export default function FreeShippingManage() {
  LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const [rows, setRows] = useState([]);
  const [filterRows,setFilterRows] = useState([]);

  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [isUsed,setIsUsed] =useState(false)


  const [start,setStart] =useState(dayjs().add(-20,'day'))
  const [end,setEnd] =useState(dayjs().add(10,'day'))

  const [customerName,setCustomerName] =useState('')
  const [orderId,setOrderId] =useState(null)
  const [transportTicketThreshold,setTransportTicketThreshold] =useState(0)

  const [transportTicketsAmount, settransportTicketsAmount] = useState(0);

  const freeShippingColumns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'customerName',
      headerName: '顧客名稱',
      width: 100,
      editable: false,
    },
    {
      field: 'orderId',
      headerName: '訂單編號',
      width: 100,
      editable: false,
    },
    {
      field: 'shippingCost',
      headerName: '免運價值',
      width: 100,
      editable: false,
    },
    {
      field: 'threshold',
      headerName: '贈送門檻',
      width: 100,
      editable: false,
    },
    {
      field: 'createDate',
      headerName: '贈送日期',
      width: 100,
      editable: false,
      valueFormatter: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        const minutes = (`0${date.getMinutes()}`).slice(-2);
        const hours = (`0${date.getHours()}`).slice(-2);
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${hours}:${minutes}`;
      },
    },
    {
      field: 'usedDate',
      headerName: '使用日期',
      width: 100,
      editable: false,
      valueFormatter: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        const minutes = (`0${date.getMinutes()}`).slice(-2);
        const hours = (`0${date.getHours()}`).slice(-2);
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${hours}:${minutes}`;
      },
    },
    {
      field: 'isUsed',
      headerName: '已使用',
      width: 60,
      editable: false,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchFreeShippingTicketsData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/manufacturer/freeshippingtickets`, {
        params: {
          start: start ? start.format('YYYY-MM-DD') : null,    // 确保有值时才传递
          end: end ? end.format('YYYY-MM-DD') : null,          // 确保有值时才传递
          orderId: orderId || null,                    // 只在有值时传递
          customerName: customerName || null,                  // 防止空字符串
          isUsed: isUsed,                                      // 布尔值
          pageNumber: page + 1,                                // 页码，后端一般从 1 开始
          pageSize: rowsPerPage                                // 每页条数
        }
      });
      
      if (response.status === 200) { 
        setRows(response.data.source);
        setFilterRows(response.data.source);
        setTotalRows(response.data.totalItemCount)
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  

  const fetchThresholdData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/manufacturer/thresholds`);
      
      if (response.status === 200) { 
        setTransportTicketThreshold(response.data.source.transportTicketThreshold);
        settransportTicketsAmount(response.data.source.numberOfTransportTickets);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handleModifyTransportTicketSubmit = async () => {
    try {
      const response = await axios.put(`${API_PATH}/manufacturer/transportTicketThreshold`, {
        transportTicketThreshold: transportTicketThreshold,
      });

      if (response.status === 200) {
        alert('修改成功');
      } else {
        alert('修改失敗');
      }
    } catch (error) {
      console.error('修改失敗', error);
      alert('請求出錯');
    }
  };

  useEffect(() => {
    fetchThresholdData();
    fetchFreeShippingTicketsData();
  }, [page,rowsPerPage]); 

  return (
  <>
  <Grid container spacing={2} style={{ marginBottom: '1%' }}>
    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
      <Typography variant="h2" component="h2">
        免運券管理
      </Typography>
    </Grid>
  </Grid>
  <Box sx={{ height: 600, width: '90%', margin: 'auto'}}>
    <Grid container spacing={2} style={{ marginBottom: '1%' }}>
      <Grid item xs={4} sx={{ mt: 0, mr: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="起始日"
            value={start}
            onChange={(e) => setStart(dayjs(e))}
            renderInput={(params) => <TextField {...params} />}
          />
          ~
          <DatePicker
            label="終止日"
            value={end}
            onChange={(e) => setEnd(dayjs(e))}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center',marginTop:'1%' }}>
        <TextField
          fullWidth
          id="outlined-number"
          size="small"
          label="消費者名稱"
          InputLabelProps={{
            shrink: true,
          }}
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
        />
      </Grid>
      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center',marginTop:'1%' }}>
        <TextField
          fullWidth
          id="outlined-number"
          size="small"
          label="訂單編號"
          InputLabelProps={{
            shrink: true,
          }}
          value={orderId}
          onChange={(e) => setOrderId(e.target.value)}
        />
      </Grid>
      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center',marginTop:'1%' }}>
        <FormControlLabel
          style={{marginBottom:'15%'}}
          control={
            <Checkbox
              checked={isUsed}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={(e) => setIsUsed(e.target.checked)}                 
            />
          }
          label={
            <Typography variant="body2" style={{ fontFamily: 'Arial', fontSize: '12px' }}>
              是否已使用
            </Typography>
          }
        />
      </Grid>
      <Button style={{marginTop:'2%' }} startIcon={<SearchIcon />} onClick={fetchFreeShippingTicketsData}>搜尋</Button>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h6" component="h2">
          當月已贈送{transportTicketsAmount}張抽獎券
        </Typography>
      </Grid>
      <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}/>
      <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
        <TextField
          fullWidth
          id="outlined-number"
          size="small"
          label="免運券消費門檻"
          InputLabelProps={{
            shrink: true,
          }}
          value={transportTicketThreshold}
          onChange={(e) => setTransportTicketThreshold(e.target.value)}
        />
      </Grid>
      <Button style={{marginTop:'2%' }} startIcon={<EditIcon />} onClick={handleModifyTransportTicketSubmit}>設定</Button>
    </Grid>
    <DataGridPro
      rows={filterRows}
      columns={freeShippingColumns}
      disableRowSelectionOnClick
    />
    <TablePagination
      component="div"
      count={totalRows}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[50, 100, 200, 500]}
    />
  </Box>
    </>
  );
}

