import { useState,useRef,useEffect,useCallback} from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LicenseInfo,DataGridPro } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import dayjs from 'dayjs';
import { FormControl, InputLabel, Select, MenuItem,useMediaQuery,IconButton } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TablePagination from '@mui/material/TablePagination';
import { Link,useNavigate } from 'react-router-dom';
import LockResetIcon from '@mui/icons-material/LockReset';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CancelIcon from '@mui/icons-material/Cancel';
import OrderSearch from '../search/OrderSearch';
import FinishedAlert from '../finishView/FinishedAlert';


const API_PATH = process.env.REACT_APP_API_PATH;

const columnVisibilityModel = {
  id:true
}

export default function TicketsManage() {
  LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

  const [rows1, setRows1] = useState([]);
  const [filterRows,setFilterRows] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [isUsed,setIsUsed] =useState(false)

  const [start,setStart] =useState(dayjs().add(-20,'day'))
  const [end,setEnd] =useState(dayjs().add(10,'day'))

  const [customerName,setCustomerName] =useState('')
  const [commodityId,setCommodityId] =useState(null)
  const [lotteryTicketThreshold,setLotteryTicketThreshold] =useState(0)
  const [lotteryTicketsAmount, setLotteryTicketsAmount] = useState(0);


  const ticketsColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'customerName',
      headerName: '顧客名稱',
      width: 150,
      editable: false,
    },
    {
      field: 'commodityName',
      headerName: '商品名稱',
      width: 200,
      editable: false,
    },
    {
      field: 'createDate',
      headerName: '贈送日期',
      width: 120,
      editable: false,
      valueFormatter: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        const minutes = (`0${date.getMinutes()}`).slice(-2);
        const hours = (`0${date.getHours()}`).slice(-2);
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${hours}:${minutes}`;
      },
    },
    {
      field: 'usedDate',
      headerName: '使用日期',
      width: 120,
      editable: false,
      valueFormatter: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        const minutes = (`0${date.getMinutes()}`).slice(-2);
        const hours = (`0${date.getHours()}`).slice(-2);
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${hours}:${minutes}`;
      },
    },
    {
      field: 'isUsed',
      headerName: '已使用',
      width: 100,
      editable: false,
    },
  ];

  const handleChangePage1 = (event, newPage) => {
    setPage(newPage)
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const fetchTicketsData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/manufacturer/tickets`, {
        params: {
          start: start ? start.format('YYYY-MM-DD') : null,    // 确保有值时才传递
          end: end ? end.format('YYYY-MM-DD') : null,          // 确保有值时才传递
          commodityId: commodityId || null,                    // 只在有值时传递
          customerName: customerName || null,                  // 防止空字符串
          isUsed: isUsed,                                      // 布尔值
          pageNumber: page + 1,                                // 页码，后端一般从 1 开始
          pageSize: rowsPerPage                                // 每页条数
        }
      });
      
      if (response.status === 200) { 
        setRows1(response.data.source);
        setFilterRows(response.data.source);
        setTotalRows(response.data.totalItemCount)
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchThresholdData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/manufacturer/thresholds`);
      
      if (response.status === 200) { 
        setLotteryTicketThreshold(response.data.source.lotteryTicketThreshold);
        setLotteryTicketsAmount(response.data.source.numberOfLotteryTickets);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handleModifyLotteryTicketThresholdSubmit = async () => {
    try {
      const response = await axios.put(`${API_PATH}/manufacturer/lotteryTicketThreshold`, {
        lotteryTicketThreshold: lotteryTicketThreshold,
      });

      if (response.status === 200) {
        alert('修改成功');
      } else {
        alert('修改失敗');
      }
    } catch (error) {
      console.error('修改失敗', error);
      alert('請求出錯');
    }
  };

  useEffect(() => {
    fetchTicketsData();
    fetchThresholdData();
  }, [page,rowsPerPage]); 

  return (
  <>
  <Grid container spacing={2} style={{ marginBottom: '1%' }}>
    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
      <Typography variant="h2" component="h2">
        抽獎券管理
      </Typography>
    </Grid>
  </Grid>
  <Box sx={{ height: 600, width: '90%', margin: 'auto' }}>
    <Grid container spacing={2} style={{ marginBottom: '1%' }}>
      <Grid item xs={4} sx={{ mt: 0, mr: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="起始日"
            value={start}
            onChange={(e) => setStart(dayjs(e))}
            renderInput={(params) => <TextField {...params} />}
          />
          ~
          <DatePicker
            label="終止日"
            value={end}
            onChange={(e) => setEnd(dayjs(e))}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center',marginTop:'1%' }}>
        <TextField
          fullWidth
          id="outlined-number"
          size="small"
          label="消費者名稱"
          InputLabelProps={{
            shrink: true,
          }}
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
        />
      </Grid>
      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center',marginTop:'1%' }}>
        <TextField
          fullWidth
          id="outlined-number"
          size="small"
          label="商品編號"
          InputLabelProps={{
            shrink: true,
          }}
          value={commodityId}
          onChange={(e) => setCommodityId(e.target.value)}
        />
      </Grid>
      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center',marginTop:'1%' }}>
        <FormControlLabel
          style={{marginBottom:'15%'}}
          control={
            <Checkbox
              checked={isUsed}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={(e) => setIsUsed(e.target.checked)}                 
            />
          }
          label={
            <Typography variant="body2" style={{ fontFamily: 'Arial', fontSize: '12px' }}>
              是否已使用
            </Typography>
          }
        />
      </Grid>
      <Button style={{marginTop:'2%' }} startIcon={<SearchIcon />} onClick={fetchTicketsData}>搜尋</Button>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center'}}>
        <Typography variant="h6" component="h2">
          當月已贈送{lotteryTicketsAmount}張抽獎券 
        </Typography>
      </Grid>
      <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center'}}/>
      <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
        <TextField
          fullWidth
          id="outlined-number"
          size="small"
          label="抽獎券消費門檻"
          InputLabelProps={{
            shrink: true,
          }}
          value={lotteryTicketThreshold}
          onChange={(e) => setLotteryTicketThreshold(e.target.value)}
        />
      </Grid>
      <Button style={{marginTop:'2%' }} startIcon={<EditIcon />} onClick={handleModifyLotteryTicketThresholdSubmit}>設定</Button>
    </Grid>
    <DataGridPro
      rows={filterRows}
      columns={ticketsColumns}
      disableRowSelectionOnClick
    />
    <TablePagination
      component="div"
      count={totalRows}
      page={page}
      onPageChange={handleChangePage1}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage1}
      rowsPerPageOptions={[50, 100, 200, 500]}
    />
  </Box>
    </>
  );
}

