import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useState,useRef,useEffect,useCallback} from 'react';
import axios from 'axios';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { CircularProgress, Box } from '@mui/material';

// ----------------------------------------------------------------------
const API_PATH = process.env.REACT_APP_API_PATH;

export default function DashboardAppPage() {
  const theme = useTheme();
  const [statisticsOfCommodity,setStatisticsOfCommodity] = useState(0);
  const [statisticsOfNewCustomer,setStatisticsOfNewCustomer] = useState(0);
  const [salesGrowthRate,setSalesGrowthRate] = useState(0);
  const [totalReclaimAmountLastMonth,setTotalReclaimAmountLastMonth] = useState(0);
  const [statisticsOfTags,setStatisticsOfTags] = useState([]);
  const [monthlyStatistics,setMonthlyStatistics] = useState([]);
  const [statisticsOfCategory,setStatisticsOfCategory] = useState([]);
  const [timeLineNews,setTimeLineNews] = useState([]);
  const [latestOrders,setLatestOrders] = useState([]);
  const [oldOrders,setOldOrders] = useState([]);
  const [loading, setLoading] = useState(true); 

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/manufacturer/dashboard`);
  
      if (response.status === 200) { 
        setStatisticsOfCommodity(response.data.source.statisticsOfCommodity);
        setStatisticsOfNewCustomer(response.data.source.statisticsOfNewCustomer);
        setSalesGrowthRate(response.data.source.salesGrowthRate);
        setTotalReclaimAmountLastMonth(response.data.source.totalReclaimAmountLastMonth);
        setStatisticsOfTags(response.data.source.statisticsOfTags);
        setMonthlyStatistics(response.data.source.monthlyStatistics);
        setStatisticsOfCategory(response.data.source.statisticsOfCategory);
        setTimeLineNews(response.data.source.timeLineNews);
        setLatestOrders(response.data.source.latestOrders);
        setOldOrders(response.data.source.oldOrders);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    fetchData()
  }, []); 

  if (loading) {
    // 資料加載中時顯示轉圈動畫
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title> 銷售後台儀表板 </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi,{localStorage.getItem('name')}, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="當月銷售額" total={statisticsOfCommodity} icon={'ant-design:android-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="當月新顧客" total={statisticsOfNewCustomer} color="info" icon={'ant-design:apple-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="與前月相比成長率" total={salesGrowthRate} color="warning" icon={'ant-design:windows-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="上月回收金額總計" total={totalReclaimAmountLastMonth} color="error" icon={'ant-design:bug-filled'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="年度銷售額"
              subheader="請自動將銷售額 * 1K"
              chartLabels={monthlyStatistics.map(data => data.month)}
              chartData={[
                {
                  name: '銷售額',
                  type: 'column',
                  fill: 'solid',
                  data: monthlyStatistics.map(data => data.salesAmount/1000),
                },
                // {
                //   name: 'Team B',
                //   type: 'area',
                //   fill: 'gradient',
                //   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                // },
                {
                  name: '顧客人數',
                  type: 'line',
                  fill: 'solid',
                  data: monthlyStatistics.map(data => data.customerCount),
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="賞品種類"
              chartData={
                statisticsOfCategory.map(data => ({
                  label: data.category,
                  value: data.totalSalesAmount
                }))
              }
              
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            <AppConversionRates
              title="熱門標籤統計"
              // subheader="(+43%) than last year"
              chartData={
                statisticsOfTags.map(data => ({
                  label: data.tagName,
                  value: data.totalSalesAmount
                }))
              }
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          <Grid item xs={12} md={6} lg={7}>
            <AppNewsUpdate
              title="最新配送請求"
              list={
                latestOrders.map(data => ({
                  id: data.id,
                  title: data.title,
                  description:data.description,
                  image:data.prizeImgUrl,
                  postedAt:data.createDate
                }))
              }
              // list={[...Array(5)].map((_, index) => ({
              //   id: faker.datatype.uuid(),
              //   title: faker.name.jobTitle(),
              //   description: faker.name.jobTitle(),
              //   image: `/assets/images/covers/cover_${index + 1}.jpg`,
              //   postedAt: faker.date.recent(),
              // }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <AppOrderTimeline
              title="即時消息"
              list={
                timeLineNews.map(data => ({
                  id: data.id,
                  title: data.title,
                  type:data.type,
                  time:data.time,
                }))
              }
            />
          </Grid>

          <Grid item xs={12} md={6} lg={7}>

            <AppTrafficBySite
              title="賞品種類銷售個數"
              list={
                statisticsOfCategory.map(data => ({
                  name: data.category,
                  value: data.salesCount,               
                }))
              }
            />
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <AppTasks
              title="緊急任務"
              list={
                oldOrders.map(data => ({
                  id: data.id,
                  label: data.title,
                  image:data.prizeImgUrl,
                  postedAt:data.createDate
                }))
              }
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
