import React, { createContext, useState } from 'react';

// 创建一个 Context 对象
export const GlobalStateContext = createContext();

// 创建一个 Provider 组件，负责提供全局状态
export const LogoContext = ({ children }) => {
  const [logoUrl, setLogoUrl] = useState("");

  return (
    <GlobalStateContext.Provider value={{ globalValue: logoUrl, setLogoUrl }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
