// @mui
import PropTypes from 'prop-types';
import { Card, Typography, CardHeader, CardContent } from '@mui/material';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector } from '@mui/lab';
// utils
import { fDateTime } from '../../../utils/formatTime';

// ----------------------------------------------------------------------

AppLastNewsline.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppLastNewsline({ title, subheader, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent
        sx={{
          '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none',
          },
        }}
      >
        <Timeline>
          {list.map((item, index) => (
            <OrderItem key={item.id} item={item} isLast={index === list.length - 1} />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}

// ----------------------------------------------------------------------

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  item: PropTypes.shape({
    time: PropTypes.instanceOf(Date),
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

function OrderItem({ item, isLast }) {
  const { type, title, time } = item;

  // 根據 title 判斷等級
  const level = 
  (title.includes('C') && 'primary 普通\n備註:稍微注意一下即可') ||
  (title.includes('D') && 'primary 普通\n備註:稍微注意一下即可') ||
  (title.includes('E') && 'primary 普通\n備註:稍微注意一下即可') ||
  (title.includes('B') && 'success 有點痛\n備註:注意一下即可') ||
  (title.includes('A') && 'info 會痛\n備註:注意荷包有無大失血') ||
  (title.includes('SP') && 'warning 這次真的痛\n備註:注意荷包有無大失血') ||
  (title.includes('最後') && 'error 恭喜\n備註:該系列已成功賣光') ||
  (type === '安全量提醒' && 'error 恭喜\n備註:該系列快要賣光了哦');


  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={
            (title.includes('C') && 'primary') ||
            (title.includes('D') && 'primary') ||
            (title.includes('E') && 'primary') ||
            (title.includes('B') && 'success') ||
            (title.includes('A') && 'info') ||
            (title.includes('SP') && 'warning') ||
            (title.includes('最後') && 'error') ||
            (type === '安全量提醒' && 'error')
          }
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-line' }}>
          {title} 等級: {level}
        </Typography>

        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {fDateTime(time)}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

